<template lang="">
  <!-- 放文件 -->
  <div class="fun-area-wrap fun-area-wrap-border" :uploadingFlag="uploadingFlag" v-if="checkAppState() == 'start'">
    <div class="grid-content bg-purple-light" @click="changeClickOrDrag">
      <el-upload class="upload-demo upload-area upload-div dotteBox" drag ref="upload" action="" :auto-upload="false"
        multiple :show-file-list="false" :on-change="(file,fileList)=>clickUpload(file,fileList,'click')"
        :file-list="fileList" accept="application/pdf">
        <div class="flexBox" :key="index">
          <div style="display:flex;flex-direction: column;align-items: center;">
            <img class="ptw" src="../assets/compress/compress.svg" alt="">
            <p style="font-size:32px;font-weight:bold;margin:40px 0 8px 0px;line-height: 44px;">
              {{$t(`message.FunOperat.compressPDF.Operat`)}}
            </p>
            <p
              style="font-family: 'Inter';font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.FunOperat.compressPDF.p`)}}</p>
            <p
              style="font-family: 'Inter';font-style: normal;margin:64px 0 8px 0;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.selectFile[3]`)}}
            </p>
            <div class="selectButton cursor">{{$t(`message.selectFile[1]`)}}</div>
            <div style="margin-top:32px">
              <svg style="margin-top:-3px ;" width="13" height="15" viewBox="0 0 13 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.65811 0.0256583C6.55548 -0.00855278 6.44452 -0.00855278 6.34189 0.0256583L0.341886 2.02566C0.137715 2.09372 0 2.28478 0 2.5V9.5C0 9.60554 0.0333988 9.70838 0.0954135 9.79379C0.286104 10.0564 0.470108 10.3171 0.651201 10.5737L0.651239 10.5737C1.27002 11.4504 1.85483 12.2789 2.5563 12.9713C3.49179 13.8947 4.64103 14.5911 6.38941 14.9876C6.46157 15.004 6.53646 15.0041 6.60868 14.988C9.8687 14.2621 11.3239 12.288 12.9222 9.7678C12.973 9.68771 13 9.59483 13 9.5V2.5C13 2.28478 12.8623 2.09372 12.6581 2.02566L6.65811 0.0256583ZM1 9.33811V2.86038L6.5 1.02705L12 2.86038V9.35452C10.479 11.7411 9.22029 13.3468 6.50088 13.9869C4.99483 13.6278 4.04041 13.0311 3.25878 12.2596C2.62646 11.6355 2.11047 10.9051 1.50375 10.0462L1.50369 10.0461C1.3434 9.81922 1.17677 9.58335 1 9.33811ZM9.89468 5.80696C10.0642 5.58899 10.0249 5.27485 9.80697 5.10532C9.589 4.93578 9.27486 4.97505 9.10532 5.19302L5.95305 9.24594L4.35355 7.64644C4.15829 7.45118 3.84171 7.45118 3.64645 7.64644C3.45118 7.8417 3.45118 8.15828 3.64645 8.35355L5.64645 10.3535C5.74786 10.455 5.88793 10.5079 6.03107 10.499C6.17421 10.4901 6.30663 10.4202 6.39468 10.307L9.89468 5.80696Z"
                  fill="#BBBBBC" />
              </svg>
              <span
                style="color: rgba(29, 29, 31, 0.3);margin-left:8px;font-family: 'Inter';font-style: normal;font-size: 16px;color: rgba(29, 29, 31, 0.3);">{{$t(`message.selectText[1]`)}}</span>
            </div>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
  <!-- 放了文件之后 -->
  <div id="drop-area" @drop="dropEvent" @dragenter.prevent @dragover.prevent style="width: 834px;"
    class="fun-area-wrap border-left" v-if="checkAppState() == 'operate'">
    <div class="grid-content" style="height: 507px;">
      <div id="pre-pdf-area" class="pdf-container">
        <canvas v-for="page in fileList.length" :id="'canvas' + page" :key="page"></canvas>
      </div>
      <div class="pre-icon-wrap">
        <div v-for="(element, index) in fileList" :key="index">
          <div class="item-cell" @drop.prevent.stop>
            <!-- <div class="item-cell"> -->
            <div class="pre-img-cell-wrap">
              <!-- <div class="pre-num" @click="removeSingleFile(index)"
                v-if="(element.preImg && element.compresssize && element.compresssize.high_definition)||(element.preImg && element.fail)"> -->
              <div class="pre-num" @click="removeSingleFile(index)" v-if="element.preImg ">
                <img src="../assets/compress/delete.svg" alt="">
              </div>
              <div class="pre-bg">
                <div class='pre-img-cell-conner d-flex justify-content-center align-items-center'
                  style="height: 91.5%;">
                  <img v-if="element.preImg" class="pre-img-cell" :src="element.preImg" alt="" />
                  <!-- <img v-if="element.preImg && element.compresssize && element.compresssize.high_definition"
                    class="pre-img-cell" :src="element.preImg" alt="" /> -->
                  <!-- 破损文件 -->
                  <img v-else src="../assets/merge/loading.gif" alt="">
                </div>
              </div>
            </div>
            <div class="file-name-wrap" style="cursor:default" v-if="element.name.length <= 17">
              <p>{{ element.name}}</p>
              <!-- <p>{{ element.name.substring(0, element.name.lastIndexOf(".")) }}</p>
              <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
            </div>
            <div class="file-name-wrap" style="cursor:default" :title="element.name" v-else>
              <p>
                {{
                element.name.substring(0,7)}}...{{element.name.substring(element.name.length - 9)}}
              </p>
              <!-- <p>{{
                element.name.substring(0,7)}}...{{element.name.substring(element.name.lastIndexOf(".")-7,element.name.lastIndexOf("."))}}
              </p>
              <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
            </div>
            <div class="flex-justify-between d-flex hover-operation-area"
              v-if="!element.fail && element.preImg && element.compresssize && element.compresssize.high_definition">
              <span>{{targetType == 0 ? element.compresssize.high_definition_r + " (" +
                element.compresssize.high_compression + ")"
                : (targetType == 1 ?
                element.compresssize.regular_compression_r + " (" + element.compresssize.regular_compression + ")" :
                element.compresssize.high_compression_r + " (" +element.compresssize.high_compression + ")" )
                }}</span>
            </div>

          </div>
        </div>

      </div>
    </div>
    <hr style="background-color: grey; margin: 0" />
    <div style="display: flex;justify-content: space-between;height: 40px;">
      <div class="add-filelength">
        <span class="file-style">{{$t(`message.FunOperat.mergePDF.add.text[0]`)}}</span>
        <span class="img-length-wrap" :imglength="fileList.length">
          <span class="img-current-length">{{ fileList.length }}</span>
          <span class="img-current-length">/5</span>
        </span>
      </div>
      <div v-if="fileList.length < 5" class="uploadBox">
        <el-upload action="" :auto-upload="false" :show-file-list="false" multiple :on-change="clickUpload"
          :file-list="fileList" ref="my-upload" accept="application/pdf" :disabled="fileList.length == 5">
          <button id="add-img-btn" :disabled="fileList.length == 5">
            <img src="../assets/merge/add.svg" alt="" />
            <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          </button>
        </el-upload>
      </div>
      <div v-else class="uploadBox">
        <button id="add-img-btn1" :disabled="fileList.length == 5">
          <img src="../assets/merge/grey-add.svg" alt="" />
          <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          <span class="not-more-four">{{$t(`message.FunOperat.mergePDF.add.text[3]`)}}</span>
        </button>
      </div>
    </div>
  </div>
  <div style="height: 548px;background: #EAEAEA;width: 1px;"></div>
  <div style="width:389px" class="action-area-wrap fun-area-wrap border-right" v-if="checkAppState() == 'operate'">
    <!-- 清晰度优先和缩小优先 -->
    <div class="add-img-wrap">
      <div>
        <h3 style='text-align: center;'>{{$t(`message.FunOperat.compressPDF.Operat`)}}</h3>
      </div>
    </div>
    <div>
      <div :class="{chooseTypeDiv:true,actived: targetType == '0'}">
        <div :class="{ chooseType: true, actived: targetType == '0' }" @click="changeType('0')">
          <span>{{$t(`message.FunOperat.compressPDF.Compression.text[0]`)}}</span>
          <span></span>
          <!-- <span>{{totalFile.high_definition ? totalFile.high_definition : 'loading...'}}</span> -->
          <p>{{$t(`message.FunOperat.compressPDF.Compression.p[0]`)}}</p>
        </div>
      </div>
      <div :class="{chooseTypeDiv:true,actived: targetType == '1'}">
        <div :class="{ chooseType: true, actived: targetType == '1' }" @click="changeType('1')">
          <span>{{$t(`message.FunOperat.compressPDF.Compression.text[1]`)}}</span>
          <span></span>
          <!-- <span>{{ totalFile.regular_compression ? totalFile.regular_compression : 'loading...'}}</span> -->
          <p>{{$t(`message.FunOperat.compressPDF.Compression.p[1]`)}}</p>
        </div>
      </div>
      <div :class="{chooseTypeDiv:true,actived: targetType == '2'}">
        <div :class="{ chooseType: true, actived: targetType == '2' }" @click="changeType('2')">
          <span>{{$t(`message.FunOperat.compressPDF.Compression.text[2]`)}}</span>
          <span></span>
          <!-- <span>{{totalFile.high_compression ? totalFile.high_compression : 'loading...'}}</span> -->
          <p>{{$t(`message.FunOperat.compressPDF.Compression.p[2]`)}}</p>
        </div>
      </div>

    </div>

    <button id="btn-convert" @click="convertPromise() " v-if="canClick">
      {{$t(`message.FunOperat.compressPDF.Operat`)}}
    </button>
    <button id="btn-convert1" v-else>
      {{$t(`message.UploadFun.title`)}}
    </button>
  </div>
  <el-col class="fun-area-wrap fun-area-wrap-border" :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
  <failUpload class="fun-area-wrap fun-area-wrap-border" v-if="checkAppState() == 'failUpload'" />
  <failConvert class="fun-area-wrap fun-area-wrap-border" v-if="checkAppState() == 'failConvert'" />

  <download class="fun-area-wrap fun-area-wrap-border" v-model:currentFun.sync="currentFun"
    v-model:downflag.sync="downInof.downflag" v-if="checkAppState() == 'converted'" />
</template>
<script>
  import { changeType, checkAppState } from "../components/convertFun/convert";
  import PDF from "pdfjs-dist";
  import { openPop, removeSingleFile } from "../api/common";
  import converting from "../components/common/converting.vue";
  import download from "../components/Download.vue";
  import "../components/convertFun/convert.scss";
  import failConvert from "../components/failConvert.vue";
  import failUpload from "../components/failUpload.vue";
  export default {
    data() {
      return {
        fileList: [],
        fileType: "pdf",
        fileSize: 0,
        filelen: 0,
        failArray: [],
        uploadingFlag: false,
        currentFun: "Compress Pdf",
        clickFlag: true,
        uploadState: "",
        downInof: {
          downUrl: "",
          downName: "",
          downFileType: "pdf",
          downflag: false,
        },
        totalFile: {
          high_definition: "",
          regular_compression: "",
          high_compression: ""
        },
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "0",
        pages: 0,
        canvasList: [],
        occupancyFlag: true,
        canClick: false,
        clickCovertFlag: false,
        getFileFromState: false,
        clickUploadFlag: false,
        badFile: [], //损坏文件
        nowTime: null,
        pageName: "Compress_PDF",
        failJPG: 0,//破损文件
        fileId: 0,
        taskId: '',//任务id
        operation: ''//当前系统
      };
    },

    methods: {
      openPop,
      removeSingleFile,
      changeType,
      checkAppState,
      changeClickOrDrag() {
        this.clickUploadFlag = true;
      },
      dropEvent(e) {
        console.log("drope:", e);
        console.log("e.dataTransfer.files:", e.dataTransfer.files);
        e.stopPropagation()
        e.preventDefault()
        let _this = this
        e.dataTransfer.files.forEach((el) => {
          el.raw = el
          let obj = {}
          for (let i in el) {
            obj[i] = el[i]
          }
          _this.clickUpload(obj)
        })
      },
      clickUpload(fileMsg, ComefileList, name) {
        const _this = this;

        this.filelen = this.filelen + 1
        console.log("fileList:", fileMsg, ComefileList);
        this.$gtag.event("upload", {
          event_category: this.pageName,
          event_label: "null",
        });
        if (this.checkAppState() == "operate") {
          _this.$gtag.event("add_files", {
            event_category: _this.pageName,
            event_label: "null",
          });
        }
        this.btnloading = true
        if (this.fileList.length >= 5) {
          this.filelen--
          if (this.operation == 'Windows') {
            this.$store.commit('changeIsShowPur', true)
          }
          return
        }
        let length = _this.fileList.length
        console.log("length:", length);
        this.checkFile(fileMsg, ComefileList).then(function name() {
          _this.canClick = false
          _this.uploadToServerProgress(length);
        }).catch((e) => {
          console.log("checkFilefail");
          _this.filelen--
          if (_this.filelen == 0) {
            _this.btnloading = false
          }
        })
      },
      uploadToServerProgress(index) {
        // let gaStartime = new Date().getTime();

        this.uploadState = "2";
        const _this = this;

        console.log("this.fileList:", this.fileList);
        console.log("num:", index);

        const event = event || window.event;
        const file = this.fileList[index].raw ? this.fileList[index].raw : this.fileList[index];
        this.fileList[index]["document"] = file;
        this.$store.commit("changeAppState", "operate");
        const uploadFile = this.fileList[index].document;
        //console.log("this.fileList[index]:", this.fileList[index]);
        this.reTryUpload(3, "/app/uploadFile", uploadFile, index, _this);
      },
      clickConvert() {
        this.clickCovertFlag = true;
        this.$store.commit("changeAppState", "converting");
        // window.ga("send", "event", "Compress PDF", "Click Compress");
      },
      //检查类型
      checkFile(fileMsg, ComefileList) {
        const _this = this;
        return new Promise((res, rej) => {
          _this.fileType = fileMsg.name.toLowerCase().substr(fileMsg.name.lastIndexOf(".") + 1);
          //console.log("this.fileSize:", _this.fileSize, fileMsg.size);
          if (_this.fileType.toLowerCase() != "pdf") {
            _this.openPop(_this, 2);
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "format_error",
            });
            this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            rej();
            return
          } else if (fileMsg.size > 30 * 1024 * 1024) {
            _this.openPop(_this, 3);
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            _this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            if (_this.operation == 'Windows') {
              _this.$store.commit('changeIsShowPur', true)
            }
            rej();
            return
          } else if (_this.fileSize + fileMsg.size > 100 * 1024 * 1024) {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: _this.$t(`message.UploadFun.message[0]`),
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            _this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            if (_this.operation == 'Windows') {
              _this.$store.commit('changeIsShowPur', true)
            }
            rej();
            return
          }
          console.log("----------========");
          _this.fileSize += fileMsg.size
          _this.fileList.push(fileMsg)
          console.log("_this.fileList:", _this.fileList);
          res();
        });
      },

      //重试
      reTryUpload(times, api, url, num, _this) {
        console.log("----------------:", times, api, url, num, _this);
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, api, url, num, _this) {
            // //console.log(`第${times}次重试`);
            _this.$http
              .post(api, {
                token: _this.$store.state.pdfToken,
                'files[]': url,
                task_id: _this.taskId
              }).then((res) => {
                if (res.code == 200) {
                  _this.failArray.push(1)
                  let reader = new FileReader();
                  reader.readAsDataURL(_this.fileList[num].raw);
                  reader.onload = () => {
                    _this.loadFile(reader.result, num, res.data.response_file[0]);
                  }
                  reader.onerror = (error) => {
                    //console.log("加载出错:", error);
                  };
                  // }).catch((e) => {
                  //     console.log();
                  // })
                  _this.fileType = _this.fileList[0].name.substr(
                    _this.fileList[0].name.lastIndexOf(".") + 1
                  );
                  _this.clickFlag = true;
                  _this.uploadState = "1";
                  // _this.btnloading = false
                  _this.fileList.length > 0 ? (_this.fileList[num]["fileUrl"] = res.data.response_file[0]) : null;
                } else {
                  _this.uploadState = '0'
                }
                resolve(res)
              }).catch((err) => {
                //console.log("err:", err);
                if (times > 0) {
                  setTimeout(() => {
                    reTryFunc(times - 1, api, url, num, _this)
                  })
                } else {
                  _this.uploadState = '0'
                  _this.failArray.push(2)
                  reject(err)
                }
              }).finally(() => {
                //console.log("finally:", _this.failArray.length, _this.filelen);
                //当请求次数和文件数量相同时在进行操作
                if (_this.failArray.length == _this.filelen) {
                  console.log("数量相同");
                  setTimeout(() => {
                    _this.canClick = true
                    let fail = _this.failArray.filter((item) => item == 2) //失败的数量
                    let successArray = _this.failArray.filter((item) => item == 1) //成功的数量
                    let fail111 = _this.fileList.filter((item) => item.fail == true) //所有的损坏文件
                    let failLength = fail111.length - _this.failJPG //总共的损坏文件删掉之前的破损文件
                    _this.failJPG = fail111.length
                    let num = _this.failArray
                    console.log("fail:", fail);
                    let len1 = _this.fileList.length
                    let len2 = _this.failArray.length
                    let len3 = len1 - len2 //原先数组的长度
                    let size = 0
                    //console.log("len1:", len1, len2, len3);
                    //统计此次上传文件的大小
                    _this.failArray.forEach((item, index) => {
                      //console.log("item:", _this.fileList[index + len3]);
                      if (_this.fileList[index + len3]) {
                        //console.log("size:", _this.fileList[len3 + index].size);
                        size += _this.fileList[len3 + index].size
                      }
                      if (item == 2) {
                        _this.fileList.forEach((item, index) => {
                          _this.fileList.splice(len3 + index, 1)
                        })
                      }
                    })
                    //破损文件长度大于0
                    console.log("_this.badFile.length:", _this.badFile.length, _this.filelen, fail.length);
                    if (_this.badFile.length == _this.filelen) {
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: _this.$t(`message.UploadFun.message[5]`),
                      })
                    }
                    //如果失败的长度和文件上传的长度一致则弹窗，部分成功不弹窗，破损文件不算上传失败
                    if (fail.length == _this.filelen) {
                      console.log("-----------========");
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      })
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "network_error",
                      });
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "0.1",
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "fail",
                      });
                    } else {
                      //成功或者部分成功
                      if (successArray.length != _this.filelen) {
                        console.log("成功或者部分成功");
                        _this.$message({
                          showClose: true,
                          duration: 8000,
                          type: "error",
                          message: _this.$t(`message.UploadFun.message[3]`),
                        })
                      }
                      let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: "null",
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${updateTime}-${(size / 1024 / 1024).toFixed(3)}`,
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${_this.filelen}-${failLength}`,
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "success",
                      });
                      _this.$gtag.event("upload_size", {
                        event_category: _this.pageName,
                        event_label: (size / 1024 / 1024).toFixed(3)
                      });
                    }
                    // _this.failArray = []
                    console.log("进来了：", _this.fileList);
                    if (_this.fileList.length == 0) {
                      _this.$store.commit("changeAppState", "failUpload");
                    }
                    _this.failArray = []
                    _this.badFile = []
                    _this.filelen = 0
                  }, 500)

                }
              })
          }
          reTryFunc(times, api, url, num, _this)
        })
      },


      //change convert type
      convertPromise() {
        let _this = this
        let fail = this.fileList.filter((item) => item.fail)
        this.nowTime = new Date().getTime()
        if (fail.length > 0) {
          //有破损文件
          _this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.UploadFun.message[5]`),
          })
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "file_error",
          });
          _this.$gtag.event("process_file", {
            event_category: _this.pageName,
            event_label: "fail",
          });
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          return
        }
        _this.$gtag.event("click_compress_quantity", {
          event_category: _this.pageName,
          event_label: _this.fileList.length
        });
        let way = _this.targetType == 0 ? 'large' : (_this.targetType == 1 ? 'middle' : 'small')
        _this.$gtag.event("click_compressed_way", {
          event_category: _this.pageName,
          event_label: way
        });
        //单个文件压缩
        if (_this.fileList.length == 1) {
          _this.convertFun(_this.fileList[0]).then(res => {
            console.log("res:", res);
            if (res.code == 200) {
              this.convertNumber = res.data.result[0].number;
              _this.$http
                .ws(_this.$store.state.pdfToken, _this.convertNumber)
                .then((response) => {
                  if (response.data.status == 1) {
                    this.downInof.downUrl = response.data.output_files[0];
                    if (this.$store.state.appState == "converting") {
                      this.$store.commit("changeAppState", "converted");

                      let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                      _this.$gtag.event("process_success", {
                        event_category: _this.pageName,
                        event_label: `${covertTime}-${(_this.fileSize / 1024 / 1024).toFixed(3)}`
                      });
                      _this.$gtag.event("process_file", {
                        event_category: _this.pageName,
                        event_label: "success",
                      });
                      _this.$gtag.event("process_success", {
                        event_category: _this.pageName,
                        event_label: _this.fileList.length
                      });
                    }
                  }
                })
                .catch((response) => {
                  let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                  _this.$gtag.event("process_fail", {
                    event_category: _this.pageName,
                    event_label: "network_error",
                  });
                  _this.$gtag.event("process_file", {
                    event_category: _this.pageName,
                    event_label: "fail",
                  });
                  _this.$gtag.event("process_fail", {
                    event_category: _this.pageName,
                    event_label: covertTime,
                  });
                  this.$store.commit("changeAppState", "failConvert");
                });
            } else {
              _this.$message({
                showClose: true,
                duration: 8000,
                type: "error",
                message: _this.$t(`message.UploadFun.message[3]`),
              });
              let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
              _this.$gtag.event("process_fail", {
                event_category: _this.pageName,
                event_label: "network_error",
              });
              _this.$gtag.event("process_file", {
                event_category: _this.pageName,
                event_label: "fail",
              });
              _this.$gtag.event("process_fail", {
                event_category: _this.pageName,
                event_label: covertTime,
              });
              _this.$store.commit("changeAppState", "failConvert");
            }
          }).catch(err => {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: _this.$t(`message.UploadFun.message[3]`),
            });
            let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
            _this.$gtag.event("process_fail", {
              event_category: _this.pageName,
              event_label: "network_error",
            });
            _this.$gtag.event("process_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("process_fail", {
              event_category: _this.pageName,
              event_label: covertTime,
            });
            _this.$store.commit("changeAppState", "failConvert");
          });
        } else {
          //多文件压缩
          let fileArray = []
          let fail = 0 //失败次数
          for (let i = 0; i < _this.fileList.length; i++) {
            _this.convertFun(_this.fileList[i]).then(res => {
              // fileArray.push(res.data.request_file[0])
              if (res.code == 200) {
                this.convertNumber = res.data.result[0].number;
                _this.$http
                  .ws(_this.$store.state.pdfToken, _this.convertNumber)
                  .then((response) => {
                    if (response.data.status == 1) {
                      console.log("----------------");
                      fileArray.push(response.data.output_files[0])
                    }
                  })
                  .catch((response) => {
                    console.log("=========");
                    fail++
                    fileArray.push(res.data.request_file[0])
                  })
                  .finally(() => {
                    if (fileArray.length == _this.fileList.length) {
                      console.log("========================成功");

                      let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                      _this.$gtag.event("process_success", {
                        event_category: _this.pageName,
                        event_label: `${covertTime}-${(_this.fileSize / 1024 / 1024).toFixed(3)}`
                      });
                      _this.$gtag.event("process_file", {
                        event_category: _this.pageName,
                        event_label: "success",
                      });
                      _this.$gtag.event("process_success", {
                        event_category: _this.pageName,
                        event_label: _this.fileList.length
                      });
                      let formData = new FormData();
                      formData.append("token", _this.$store.state.pdfToken);
                      formData.append("mode", _this.targetType);
                      formData.append("task_id", this.taskId);
                      for (let i = 0; i < fileArray.length; i++) {
                        formData.append("file_url[]", fileArray[i]);
                      }
                      this.$http
                        .post("/app/compressFile", formData).then((res) => {
                          console.log("多文件压缩");
                          if (res.code == 200) {
                            _this.downInof.downUrl = res.data.result;
                            _this.downInof.downFileType = "zip";
                            console.log("下载链接：", res.data.result);
                            this.$emit("update:downUrl", _this.downInof.downUrl);
                            this.$store.commit("changeAppState", "converted");
                          }
                        })
                    }
                  })
              } else {
                fileArray.push(res.data.request_file[0])
              }
              //当所有文件压缩完
            })
              .catch(err => {
                fileArray.push(_this.fileList[i].fileUrl)
                fail++
                console.log("多文件失败");
              }).finally(() => {
                let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)

                if (fileArray.length == _this.fileList.length) {
                  console.log("------------", fileArray.length, fail, _this.fileList.length);

                  if (fail == _this.fileList.length) {
                    console.log("都相等");
                    _this.$gtag.event("process_fail", {
                      event_category: _this.pageName,
                      event_label: "network_error",
                    });
                    _this.$gtag.event("process_file", {
                      event_category: _this.pageName,
                      event_label: "fail",
                    });
                    _this.$gtag.event("process_fail", {
                      event_category: _this.pageName,
                      event_label: covertTime,
                    });
                    this.$store.commit("changeAppState", "failConvert");
                    _this.$message({
                      showClose: true,
                      duration: 8000,
                      type: "error",
                      message: _this.$t(`message.UploadFun.message[3]`),
                    });
                    return
                  }
                }
              })
          }
        }
      },
      convertFun(file) {
        return new Promise((res, rej) => {
          let gaStartime = new Date().getTime();

          const _this = this;
          this.canConverFlag = true;
          this.$store.commit("changeAppState", "converting");
          console.log("fileUrl:", file);
          this.$http
            .post("/app/pdfcompressv1", {
              token: this.$store.state.pdfToken,
              file_url: file.fileUrl,
              mode: this.targetType,
            })
            .then((response) => {
              res(response)
            })
            .catch((err) => {
              rej(err)
              // _this.$store.commit("changeAppState", "failConvert");
            });
        })
      },

      loadFile(url, num, file) {
        const _this = this;
        console.log("loadFile", _this.fileList);
        PDF.getDocument(url)
          .then((pdf) => {
            console.log("=============:", pdf);
            _this.$http
              .post("/app/getcompresssize", {
                token: _this.$store.state.pdfToken,
                'file_url': file,
              }).then((res) => {
                _this.fileList[num].compresssize = res.data.result
                console.log("getcompresssize:", res);
                console.log("_this.fileList[num].compresssize:", _this.fileList[num].compresssize);
              }).catch((e) => {
                console.log("--------------:", e);
              })
            this.pdfDoc = pdf;
            this.pages = this.pdfDoc.numPages;
            this.fileList[num]["pdf"] = this.pdfDoc
            this.fileList[num]["pageNums"] = this.pages
            this.$nextTick(() => {
              this.renderPage(1, num);
            });
          })
          .catch(function (err) {
            _this.$nextTick(() => {
              _this.badFile.push(num)
              _this.fileList[num].fail = true
              console.log("loadFile失败");
              _this.fileList[num].preImg = require('../assets/merge/failfiles.svg')
              _this.fileList.length == 0 ? _this.$store.commit("changeAppState", "start") : "";
            })
            return false;
          })
      },
      /*
       * 渲染PDF文件
       * */
      renderPage(num, index) {
        console.log("renderPage");
        const _this = this;
        this.pdfDoc.getPage(num).then((page) => {
          // const __this = this;
          let indexId = index + 1
          let canvas = document.getElementById("canvas" + indexId);
          let ctx = canvas.getContext("2d");
          let dpr = 1; // 设备像素比
          let bsr =
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1; // 浏览器在渲染canvas之前会用几个像素来来存储画布信息,类似img
          let ratio = dpr / bsr;
          let viewport = page.getViewport(1.5);
          canvas.width = viewport.width * ratio; // 画布大小,默认值是width:300px,height:150px
          canvas.height = viewport.height * ratio;
          canvas.style.width = viewport.width + "px"; // 画布的框架大小
          canvas.style.height = viewport.height + "px";
          let renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          this.canvasList.push(renderContext);
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then(function () {
              _this.$nextTick(() => {
                _this.occupancyFlag = false;
                _this.fileList[indexId - 1].preImg = document.querySelectorAll(".pdf-container canvas")[indexId - 1].toDataURL("image/jpg");
              })
            })
            .catch(function () {
              _this.occupancyFlag = false;
              _this.fileList[indexId - 1].preImg = require('../assets/merge/failfiles.svg')
              console.log("renderPagefail,_this.fileList:", _this.fileList);
            });
        });
      },

    },
    components: {
      converting,
      download,
      failConvert,
      failUpload
    },
    watch: {
      clickCovertFlag: {
        handler: function () {
          this.uploadState == 1 ? this.convertFun() : "";
          this.uploadState == 0 ? this.openPop(this, 5) : "";
        },
      },
      uploadState: {
        handler: function (value) {
          value == 1 && this.clickCovertFlag ? this.convertFun() : "";
          value == 0 && this.clickCovertFlag ? this.openPop(this, 5) : "";
        },
      },
      fileList: {
        handler(val) {
          const _this = this;
          if (val.length == 0) _this.targetType = 0
          if (val.length > 0) {
            this.downInof.downName = val[0].name;
          } else {
            _this.clickUploadFlag = false;
          }
          this.$store.commit("changeAppFileList", val);
        },
        deep: true, //true 深度监听
      },
    },
    mounted() {
      this.$store.commit("changeFileType", 'pdf');
      this.operation = sessionStorage.getItem('getOS');
      this.$http
        .get("/app/user/get-taskid").then((res) => {
          this.taskId = res.data.task_id
          console.log("res.data:", res.data);
        })
      if (this.$store.state.appFileList.length > 0) {
        this.getFileFromState = true;
        this.clickUpload(this.$store.state.appFileList[0]);
      }
      this.$store.commit("changeStateFunName", "compress");
    },
  };
// import { fetchList } from "@/api/splitPDF";
</script>
<style lang="scss" scoped>
  ::v-deep .el-upload {
    &:focus {
      color: black;
    }
  }

  .pre-bg {
    width: 180px;
    height: 234px;
    position: absolute;
    top: 12px;
    left: 30px;
    background: url("../assets/merge/filebg.svg") no-repeat;
  }


  .tip {
    position: relative;
  }

  .tip:hover:after {
    position: absolute;
    top: 20px;
    left: 0;
    content: attr(data-title);
    /* //在这里设内置好title出现的位容置就好了 */
    color: #000000;
    border: 1px solid #242424;
    border-radius: 5px;
    background-color: #E5E5E5;
  }

  .uploadBox {
    margin: 6px 8px;
    width: 110px;

    ::v-deep .el-upload,
    ::v-deep .el-upload-dragger {
      /* width: 100%; */
      width: 110px !important;
      height: 28px;

    }
  }

  .dotteBox {
    padding: 24px;
  }

  .fun-area-wrap {
    height: 548px;
    background: #fff;
    /* border-radius: 16px; */
  }


  .border-left {
    border-radius: 16px 0 0 16px;
  }

  .border-right {
    border-radius: 0 16px 16px 0;
  }

  .hover-area {
    background: rgba(27, 33, 39, 0.3);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 35px;
    padding: 2px 6px;
    height: 32px;
    width: 45px;
    background: rgba(27, 33, 39, 0.5);
    border-radius: 16px;

    span:hover {
      cursor: pointer;
      color: #DBEAFD !important;

    }

    .turn-right {
      margin: 0 12px;
      position: relative;
      opacity: 0.6;
    }
  }

  .hover-operation-area {
    position: absolute;
    left: 16%;
    /* transform: translate(-50%); */
    bottom: 35px;
    height: 30px;
    width: 155px;
    background: rgba(66, 96, 255, 0.6);
    z-index: 5;
    bottom: 43px;
    justify-content: center;
    align-items: center;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;

      text-align: center;

      color: #FFFFFF;
    }

    .turn-right {
      margin: 0 12px;
      position: relative;
      opacity: 0.6;
    }
  }

  .item-cell {
    text-align: center;
    position: relative;
    border-radius: 8px;

    /* .hover-operation-area {
      opacity: 1;
    } */
    .pre-num {
      position: relative;
      left: 175px;
      top: 10px;
      cursor: pointer;
      z-index: 10;
      width: 32px;
      display: none;
    }

    .hover-area {
      opacity: 0;
    }

    &:hover {
      background: #f6f6f6;

      .pre-num {
        display: block;
      }

      .hover-area {
        opacity: 1;
      }

      ~#file-name-hover {
        height: auto;
        padding: 32px 0;
        border-top: 1px solid #ccc;
        background: red;
      }
    }

    /* &.actived {
      background: #f6f6f6;

      .pre-num {
        display: block;
      }

    } */

    .pre-img-cell-wrap {
      height: 212px;
      width: 232px;
      text-align: center;



      .pre-img-cell {
        width: 155px;
        position: relative;
        display: block;
        /* z-index: 5; */

      }
    }

    .turn-right {
      margin-left: 12px;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }

    .turn-left {
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;

      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }
  }

  .pre-img-cell {
    .pre-icon {
      max-width: 212px;
      max-height: 212px;
      width: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .add-img-wrap {
    color: #1D1D1F;

    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      text-align: center;

      color: #1D1D1F;
    }


    .prompt-tit {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: rgba(29, 29, 31, 0.5);
      margin-top: 16px;
    }
  }




  .fun-area-wrap-border {
    border-radius: 16px;
  }

  .fun-area-wrap .pre-icon-wrap {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 32px 2px 32px 40px;

    .pre-icon-img-wrap {
      height: 212px;
    }

    .item-cell {
      display: inline-block;
      width: 232px;
      height: 276px;
      margin: 0 29px 32px 0;

      img {
        max-width: 232px;
        max-height: 213px;
      }
    }

    .pre-img-cell-conner {
      /* position: relative; */
      width: fit-content;
      margin: 0 auto;
      box-shadow: 0px 4px 12px rgb(33 34 42 / 10%);
      position: absolute;
      top: 7px;
      left: 7px;
    }

    .pre-img-bg {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 0;
      right: 0;
    }

  }

  .action-area-wrap {
    padding: 24px 32px;
  }


  #pre-pdf-area {
    display: none;
  }

  .file-name-wrap {
    display: flex;
    max-width: 150px;
    margin: 40px auto;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: rgba(29, 29, 31, 0.7);

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }

    p:not(.hover-tit) {
      white-space: nowrap;
      text-align: center;

      &:hover~.hover-tit {
        display: block;
      }
    }
  }

  .grid-content {
    // width:calc(100% - 4px)
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f5f5f5 #fff;
    scrollbar-width: thin;
  }

  .chooseTypeDiv {
    width: 340px;
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid transparent;

    &.actived {
      border: 2px solid #5452F6;
      box-shadow: 0px 4px 10px rgba(84, 82, 246, 0.2);
    }

    .chooseType {
      background: linear-gradient(180deg, #EEEEFE 0%, #FFFFFF 100%);
      box-sizing: border-box;
      border-radius: 8px;
      /* width: 340px; */
      border: 1px solid #EEEEFE;

      /* border: 2px solid #EEEEFE; */
      padding: 16px 24px;
      /* display: flex; */
      /* justify-content: space-between; */
      flex-wrap: wrap;

      :first-child {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #1D1D1F;
        /* margin-right: 13px; */
        display: inline-block;
      }

      :nth-child(2) {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FF7936;
      }

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: rgba(29, 29, 31, 0.7);
      }

      &:hover {
        cursor: pointer;
      }

      &.actived {
        border: none;
        /* position: relative;
        overflow: hidden;
        box-shadow: 0px 4px 10px rgba(84, 82, 246, 0.2); */

      }
    }
  }
</style>